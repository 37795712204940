import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../api/auth";
import { UserInterface } from "./index.type";

export const fetchAsyncUser :AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk(
  "user/fetchAsyncUser",
  get,
);

const initialState: UserInterface = {
  loading: false,
  currentDataset: {
    id: "", name: "Banking", imageUrl: "", sorts: [], filters: [],
  },
  user: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    parentId: "",
    datasets: [{
      id: "", name: "", imageUrl: "", sorts: [], filters: [],
    }],
  },
  status: 0,
  error: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncUser.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncUser.fulfilled]: (state, { payload }) => {
      const { name: datasetName } = state.currentDataset;
      const { datasets } = payload.data;
      const dataset = datasets.filter((val: { name: string; }) => val.name === datasetName)[0];
      return {
        ...state,
        user: payload.data,
        status: payload.status,
        loading: false,
        currentDataset: dataset,
      };
    },
    [fetchAsyncUser.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
