import AWS from 'aws-sdk';
import axios from 'axios';
import { FileInterface } from '../components/FileUpload';
import {
  AWSACCESSKEY,
  AWSSECRETKEY,
  AWSREGION,
  AWSBUCKET,
} from '../utils/constants';

const S3 = new AWS.S3({
  accessKeyId: AWSACCESSKEY,
  secretAccessKey: AWSSECRETKEY,
  region: AWSREGION,
});
const generatePresignedUrl = async (key: string, contentType: string) => {
  const params = {
    Bucket: AWSBUCKET,
    Key: key,
    ContentType: contentType,
    Expires: 60,
  };
  return S3.getSignedUrlPromise('putObject', params);
};

const uploadFileWithPresignedUrl = async (presignedUrl: string, file: FileInterface) => {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
  };

  try {
    await axios.put(presignedUrl, file, options);
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

export { generatePresignedUrl, uploadFileWithPresignedUrl };
