type State = {
    error: string;
    loading: boolean;
    success: boolean;
    data?: any;
};
type Action =
    | { type: 'SET_ERROR'; payload: string }
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_SUCCESS'; payload: boolean }
    | { type: 'SET_DATA'; payload: any };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_SUCCESS':
      return { ...state, success: action.payload };
    case 'SET_DATA':
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export {
  reducer,
}; export type { Action };
