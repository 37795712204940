import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Dialog, Box, DialogTitle, DialogActions, Button,
} from '@mui/material';
import VendorOnboardForm from './form';
import Breadcrumb from '../../components/Breadcrumb';

const AddVendor = () => {
  const navigate = useNavigate();
  const [addSuccess, setAddSuccess] = useState(false);
  return (
    <div className="">
      <Dialog
        open={addSuccess}
        onClose={() => navigate('/')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          margin={5}
        >
          <DialogTitle id="alert-dialog-title">
            Vendor has been created successfully.
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={() => navigate(0)}>Create New Vendor</Button>
            <Button variant="contained" onClick={() => navigate('/')} autoFocus>
              View Vendors
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Breadcrumb navigation={[
        {
          displayName: 'Smartvendor',
          url: `/`,
        },
        {
          displayName: 'Add Vendor',
          url: `/`,
        },
      ]}
      />
      <VendorOnboardForm onSuccess={setAddSuccess} />
    </div>
  );
};

export default AddVendor;
