import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Collapse,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  MoreVert,
  WarningRounded,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RootState } from '../../store';
import { convertCamelCaseToUpperCase, getColumns } from '../../utils/utils';
import NoResultFound from '../../components/NoResultFound';
import { NORESULTFOUNDIMAGE } from '../../utils/Images';
import { ObjectInterface } from '../../store/vendor/index.type';
import DataTable from '../../components/table';
import { BUSINESSTYPE, RISK } from '../../utils/constants';
import FilterMenu from "./Filter/index";

interface DeleteDialogInterface {
  open: boolean,
  vendorName: string,
  onClose: React.MouseEventHandler<HTMLButtonElement>,
  onConfirm: React.MouseEventHandler<HTMLButtonElement>,
}

const DeleteDialog = ({
  open, vendorName, onClose, onConfirm,
}: DeleteDialogInterface) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        margin={5}
      >
        <WarningRounded sx={{ fontSize: 100 }} color="error" />
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          {`Are you sure you want to remove ${vendorName}?`}
        </DialogTitle>
        <DialogContent />
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={onClose}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>

        </DialogActions>
      </Box>
    </Dialog>
  );
};

const Row = (props: { row: any, columns: string[], deleteVendor: (id: string) => void }) => {
  const navigate = useNavigate();
  const { row, columns, deleteVendor } = props;
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { products } = row;

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenMenu(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    } else if (event.key === 'Escape') {
      setOpenMenu(false);
    }
  };

  const handleEditClick = () => { navigate(`/edit/${row.id}`); };

  const handleView = (e: Event | React.SyntheticEvent) => {
    handleClose(e);
    navigate(`/vendor/${row.id}`);
  };

  const handleProductClick = (prodRow: ObjectInterface) => {
    navigate(`/product/${prodRow.id}`);
  };

  const handleDelete = (e: Event | React.SyntheticEvent) => {
    handleClose(e);
    setOpenDelete(true);
  };

  return (
    <>
      <DeleteDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false);
          deleteVendor(row.id);
        }}
        vendorName={row.name}
      />
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((col) => {
          const riskIcon = col === 'risk' && RISK.find((riskItem) => riskItem.label.toLowerCase() === row[col].toLowerCase())?.icon;
          return (
            <TableCell key={col}>
              <div className="tableCellContainer">
                {row[col]}
                {riskIcon && (
                  <span className="iconSpan">
                    {riskIcon}
                  </span>
                )}
              </div>
            </TableCell>
          );
        })}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            ref={anchorRef}
            onClick={handleToggle}
          >
            <MoreVert />
          </IconButton>
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{
              zIndex: 1,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={openMenu}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      className="row-menu"
                    >
                      <MenuItem onClick={handleView}>View</MenuItem>
                      <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={products && products.length > 0 && open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {row.name}
                {' '}
                Products
              </Typography>
              <DataTable data={products} onRowClick={handleProductClick} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Listing = ({ deleteVendor }: { deleteVendor: (id: string) => void }) => {
  const navigate = useNavigate();
  const { loading, vendors } = useSelector(
    (state: RootState) => state.vendorActions,
  );
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<ObjectInterface[]>([]);
  const [filteredData, setFilteredData] = useState<ObjectInterface[]>(vendors);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("asc");
  const [sortBy, setSortBy] = useState<string>("risk");
  const columns = useMemo(
    () => vendors && getColumns(vendors, ["updatedDate"]),
    [vendors],
  );
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({
    type: [],
    risk: [],
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column: string) => {
    if (column === "risk") {
      setSortBy(column);
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    }
  };

  const handleApplyFilters = (col: string, filters: string[]) => {
    setSelectedFilters({ ...selectedFilters, [col]: filters });
  };
  useEffect(() => {
    if (filteredData) {
      const sortedData = [...filteredData].sort((a, b) => {
        const aRiskIndex = RISK.findIndex(
          (item) => item.label.toLowerCase() === a[sortBy].toLowerCase(),
        );
        const bRiskIndex = RISK.findIndex(
          (item) => item.label.toLowerCase() === b[sortBy].toLowerCase(),
        );
        return sortDirection === "asc"
          ? aRiskIndex - bRiskIndex
          : bRiskIndex - aRiskIndex;
      });
      setRows(
        sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      );
    }
  }, [filteredData, page, rowsPerPage, sortDirection, sortBy]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("query");
    if (paramValue) {
      const filtered = vendors.filter(
        (ven) => ven.name.toLowerCase().includes(paramValue.toLowerCase()),
      );
      setSearchTerm(paramValue);
      setFilteredData(filtered);
    } else {
      setSearchTerm("");
      setFilteredData(vendors);
    }
  }, [location.search, vendors]);

  useEffect(() => {
    if (vendors) {
      const filteredVendors = vendors.filter((vendor) => {
        return (
          (selectedFilters.type.length === 0
            || selectedFilters.type.includes(vendor.type))
          && (selectedFilters.risk.length === 0
            || selectedFilters.risk.includes(vendor.risk))
        );
      });
      setFilteredData(filteredVendors);
    }
  }, [vendors, selectedFilters]);

  return (
    <>
      {searchTerm && (
        <Typography variant="h5" m={2}>
          Search Result for
          {" "}
          <Typography
            variant="h5"
            style={{ display: "inline" }}
            color="rgb(41, 146, 194)"
          >
            {searchTerm}
          </Typography>
        </Typography>
      )}
      {!loading && columns && columns.length > 0 && filteredData && (
        <TableContainer style={{ margin: 20, width: "auto" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((col) => (
                  <React.Fragment key={col}>
                    <TableCell
                      onClick={() => handleSort(col)}
                      className={col === sortBy ? "sort-header" : ""}
                      sx={{ cursor: "pointer" }}
                    >
                      {convertCamelCaseToUpperCase(col).toUpperCase()}
                      {col === sortBy && sortDirection === "asc" && (
                        <ArrowUpward
                          style={{ fontSize: "1.3em", marginLeft: "1.25em" }}
                        />
                      )}
                      {col === sortBy && sortDirection !== "asc" && (
                        <ArrowDownward
                          style={{ fontSize: "1.3em", marginLeft: "1.25em" }}
                        />
                      )}
                      {(col === "type" || col === "risk") && (
                        <FilterMenu
                          filters={
                            col === "type"
                              ? BUSINESSTYPE
                              : RISK.map((item) => item.label)
                          }
                          selectedFilters={selectedFilters[col]}
                          handleApplyFilters={(filters) => handleApplyFilters(col, filters)}
                        />
                      )}
                    </TableCell>
                  </React.Fragment>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row
                  key={row.id}
                  columns={columns}
                  row={row}
                  deleteVendor={deleteVendor}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {!loading && columns && columns.length === 0 && (
        <NoResultFound image={NORESULTFOUNDIMAGE} title="No Vendor Found">
          <Button variant="outlined" onClick={() => navigate(`/add`)}>
            Add Vendor
          </Button>
        </NoResultFound>
      )}
    </>
  );
};

export default Listing;
