import React, { ReactNode } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { Colors } from '../../utils/theme';

interface NoResultProps {
  image: string,
  title: string,
  children: ReactNode,
}

const NoResultFound : React.FC<NoResultProps> = ({
  image, title, children,
}) => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Avatar
        src={image}
        sx={{
          width: 100, height: 100, padding: 3, bgcolor: Colors.subduedSurface,
        }}
      />
      <Typography color={Colors.darkGrey} variant="h5">{title}</Typography>
      {children}
    </Stack>
  );
};

export default NoResultFound;
