import React, {
  useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  StyledSearchBarContainer, StyledSeeMore,
  StyledSearchContent, StyledSearchContainer,
} from './index.styles';
import {
  SearchDropDownProps,
} from './index.type';
import { ObjectInterface } from '../../store/vendor/index.type';

export const SearchDropDown = ({
  search,
  dataList,
  isClicked,
  // handleKeyDown,
  handleSearch,
}: SearchDropDownProps) => {
  const noResult = search.length > 2 && !isClicked && dataList?.length === 0;
  const isResults = search.length > 2 && dataList && dataList?.length > 0;
  const isPagination = isResults && dataList?.length > 4;

  const renderResults = () => {
    if (noResult) {
      return (<div className="no-result">No results found</div>);
    }
    if (isResults) {
      return dataList.slice(0, 5).map(({ id, name } : ObjectInterface) => (
        <StyledSearchContent to={`/vendor/${id}`}>
          <span className="name-text">
            {name?.toUpperCase()}
          </span>
        </StyledSearchContent>
      ));
    }
    return null;
  };
  return (
    <StyledSearchContainer>
      {renderResults()}
      {isPagination && (
        <StyledSeeMore
          variant="text"
          onClick={handleSearch}
        >
          <div>
            See More
          </div>
        </StyledSeeMore>
      )}
    </StyledSearchContainer>
  );
};

const Search = ({ options }: { options: ObjectInterface[] }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef<HTMLDivElement>(null);
  const inputEl: React.MutableRefObject<string | any> = useRef("");
  const [data, setData] = useState<ObjectInterface[]>([]);
  const [isClicked, setIsClicked] = useState(false);

  const getSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    setIsClicked(false);
  };

  const handleSearch = () => {
    if (searchTerm.length > 2) {
      const queryParams = new URLSearchParams();
      queryParams.set('query', searchTerm);
      navigate(`/?${queryParams.toString()}`);
      setIsClicked(true);
      setData([]);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredData = options.filter((option) => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          option.name.toLowerCase().includes(searchTermLower)
        // || option.products.some(
        //   (product: ObjectInterface) => product.name.toLowerCase().includes(searchTermLower),
        // )
        );
      });
      setData(filteredData);
    } else {
      setData([]);
    }
  }, [searchTerm, setData]);

  const handleKeyPress = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <StyledSearchBarContainer ref={searchRef}>
      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
        <InputLabel htmlFor="search">Search Vendor</InputLabel>
        <OutlinedInput
          id="search"
          type="text"
          ref={inputEl}
          onChange={getSearchTerm}
          onKeyDown={handleKeyPress}
          defaultValue={searchTerm}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="search"
                onClick={handleSearch}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
              )}
          label="Password"
        />
      </FormControl>
      <SearchDropDown
        search={searchTerm}
        dataList={data}
        isClicked={isClicked}
        // handleKeyDown={handleKeyDown}
        handleSearch={handleSearch}
      />
    </StyledSearchBarContainer>

  );
};

export default Search;
