import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { ObjectInterface } from '../../store/vendor/index.type';
import Breadcrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';
import { reducer } from '../../store/state';
import { get } from '../../api/data';
import Profile from './tabs/profile';
import Contacts from './tabs/contacts';
import Products from './tabs/products';
import TabView from '../../components/tabs';

const VendorDetail = () => {
  const { vendorId } = useParams();
  const initialState = {
    error: '',
    loading: false,
    success: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [vendor, setVendor] = useState<ObjectInterface>({});
  const { success, loading, error } = state;

  const getVendor = async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const res = await get(`vendor/${id}`);
    if (res) {
      dispatch({ type: 'SET_SUCCESS', payload: true });
      dispatch({ type: 'SET_ERROR', payload: '' });
      setVendor(res);
    } else {
      dispatch({ type: 'SET_ERROR', payload: res?.data });
    }
    dispatch({ type: 'SET_LOADING', payload: false });
    return res;
  };

  useEffect(() => {
    if (vendorId && !success && !loading && !error) getVendor(vendorId);
  }, [vendorId, success, loading, error]);

  const tabs = [
    {
      label: 'Profile',
      component: <Profile vendor={vendor} />,
    },
    {
      label: 'Contacts',
      component: <Contacts contacts={vendor.contacts} />,
    },
    {
      label: 'Products',
      component: <Products products={vendor.products} />,
    },
  ];

  return (
    <div className="">
      {loading && <Loader loading={loading} />}
      {success && (
      <>
        <Breadcrumb navigation={[
          {
            displayName: 'Smartvendor',
            url: `/`,
          },
          {
            displayName: vendor.name,
            url: `/`,
          },
        ]}
        />
        <TabView tabs={tabs} />
      </>
      )}
    </div>
  );
};

export default VendorDetail;
