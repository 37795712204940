import "../index.css";
import React, { useState } from "react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dropdown from "../../../components/Select";
import Dropzone, { FileInterface } from "../../../components/FileUpload";
import {
  CATEGORY,
  CONTRACTSTATUS,
  LICENSETYPE,
} from "../../../utils/constants";
import VendorInterface, { ProductInterface } from "./interface";
import {
  generatePresignedUrl,
  uploadFileWithPresignedUrl,
} from "../../../api/s3";

interface ProductFormInterface {
  loading: boolean;
  onSubmit: (data: VendorInterface) => Promise<void>;
  onPrevious: (data: VendorInterface) => void;
  initialValues?: ProductInterface[];
}

const ProductForm: React.FC<ProductFormInterface> = ({
  loading,
  onSubmit,
  onPrevious,
  initialValues = [],
}) => {
  const defaultProduct: ProductInterface = {
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    status: "",
    licenseType: "",
    licenseCount: "",
    category: "",
    documents: [],
    duration: { value: 1, unit: "" },
  };

  const defaultProducts: ProductInterface[] = initialValues.map(
    ({ startDate, endDate, ...prod }) => ({
      ...prod,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
    }),
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<VendorInterface>({
    defaultValues: {
      products: initialValues.length ? defaultProducts : [defaultProduct],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
    keyName: "uid",
  });
  const [removedContracts, setRemovedContracts] = useState<string[]>([]);
  const [removedDocuments, setRemovedDocs] = useState<string[]>([]);
  const [docUploading, setDocUploading] = useState(false);

  const uploadFile = async (file: FileInterface) => {
    setDocUploading(true);
    const fileData = {
      name: file.name,
      type: file.type,
      url: file.url,
      id: file.id || uuidv4(),
    };
    const id = file.id || uuidv4();
    if (!file.id) {
      const signedUrl = await generatePresignedUrl(
        `${id}/${file.name}`,
        file.type,
      );
      await uploadFileWithPresignedUrl(signedUrl, file);
      [fileData.url] = signedUrl.split("?");
    }
    setDocUploading(false);
    return fileData;
  };

  const uploadDocs = async (products: any[]) => {
    return Promise.all(
      products.map(async (prod) => ({
        ...prod,
        documents: await Promise.all(prod.documents.map(uploadFile)),
      })),
    );
  };

  const calculateEndDate = ({
    startDate,
    duration: { value: durationValue, unit: durationUnit },
  }: ProductInterface): Date => {
    let endDate = dayjs(startDate);
    if (durationUnit === "months") {
      endDate = endDate.add(durationValue, "month");
    } else if (durationUnit === "years") {
      endDate = endDate.add(durationValue, "year");
    }
    return endDate.toDate();
  };
  const handleSubmitForm = async (data: VendorInterface) => {
    try {
      if (data && Array.isArray(data.products)) {
        const productsWithUploadedDocs = await uploadDocs(data.products);
        const updatedProducts = productsWithUploadedDocs.map((prod) => ({
          ...prod,
          endDate: calculateEndDate(prod).toISOString().replace('T', ' ').replace('Z', ''),
        }));
        const payload = {
          ...data,
          products: updatedProducts,
          removedContracts,
          removedDocuments,
        };
        await onSubmit(payload);
      } else {
        console.error("Invalid data format or missing products array");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const style = { width: "auto", minHeight: "80px" };
  const iconStyle = { height: "50px", width: "50px" };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="left"
      spacing={2}
      marginTop={5}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)} className="vendor-form">
        {fields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              <Stack direction="row" justifyContent="space-between">
                <h4>{`Product: ${index + 1}`}</h4>
                <Stack direction="row" spacing={2}>
                  <IconButton
                    style={iconStyle}
                    onClick={() => {
                      const { contractId } = field;
                      if (contractId) {
                        setRemovedContracts((prev) => [...prev, contractId]);
                      }
                      remove(index);
                    }}
                    aria-label="delete"
                    size="small"
                    disabled={fields.length === 1}
                  >
                    <RemoveIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    style={iconStyle}
                    onClick={() => append(defaultProduct)}
                    aria-label="delete"
                    size="small"
                  >
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
              <div key={field.id}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <TextField
                      error={
                        !!(errors.products && errors.products[index]?.name)
                      }
                      label="Name"
                      {...register(`products.${index}.name`, {
                        required: "Name is required",
                        pattern: {
                          value:
                            /^[A-Za-z\d][A-Za-z\d\s,.'_-]*[A-Za-z\d\s.'-]$/,
                          message:
                            "Name should start with an alphabet and can contain alphabets, numbers, spaces, hyphens, and underscores",
                        },
                      })}
                      variant="outlined"
                      helperText={
                        (errors.products
                          && errors.products[index]?.name?.message)
                          || ""
                      }
                      style={style}
                    />
                    <Dropdown
                      defaultValue={field.category || ""}
                      control={control}
                      name={`products.${index}.category`}
                      label="Category"
                      options={CATEGORY}
                      error={
                        errors.products && errors.products[index]?.category
                      }
                    />
                    <Dropdown
                      defaultValue={field.licenseType || ""}
                      control={control}
                      name={`products.${index}.licenseType`}
                      label="License Type"
                      options={LICENSETYPE}
                      error={
                        errors.products && errors.products[index]?.licenseType
                      }
                    />
                    <TextField
                      error={
                        !!(
                          errors.products
                          && errors.products[index]?.licenseCount
                        )
                      }
                      label="License Count"
                      type="number"
                      {...register(`products.${index}.licenseCount`, {
                        required: "License Count is required",
                      })}
                      variant="outlined"
                      helperText={
                        (errors.products
                          && errors.products[index]?.licenseCount?.message)
                          || ""
                      }
                      style={style}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          label="Duration"
                          type="number"
                          InputProps={{ inputProps: { min: 1 } }}
                          {...register(`products.${index}.duration.value`, {
                            required: "Duration is required",
                            min: {
                              value: 1,
                              message: "Duration should be at least 1",
                            },
                          })}
                          variant="outlined"
                          defaultValue={field.duration?.value || defaultProduct.duration.value}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Dropdown
                          name={`products.${index}.duration.unit`}
                          label="Duration Unit"
                          options={["months", "years"]}
                          control={control}
                          defaultValue={field.duration?.unit || "months"}
                          error={
                            errors.products && errors.products[index]?.status
                          }
                        />
                      </Grid>
                    </Grid>
                    <Controller
                      control={control}
                      name={`products.${index}.startDate`}
                      defaultValue={
                        (initialValues[index]
                          && initialValues[index].startDate)
                          || ""
                      }
                      render={({ field: f }) => (
                        <DatePicker
                          label="Contract Start Date"
                          value={f.value}
                          onChange={(date) => f.onChange(date)}
                          inputRef={f.ref}
                          slotProps={{
                            textField: {
                              error: !!(
                                errors.products
                                && errors.products[index]?.startDate
                              ),
                              helperText:
                                (errors.products
                                  && errors.products[index]?.startDate?.message)
                                  || "",
                              style,
                            },
                          }}
                        />
                      )}
                    />
                    <TextField
                      label="Contract End Date"
                      variant="outlined"
                      value={dayjs(field.endDate || calculateEndDate(getValues(`products.${index}`))).format('MM/DD/YYYY')}
                      disabled
                      InputProps={{ readOnly: true }}
                      style={style}
                    />
                    <Dropdown
                      defaultValue={field.status || ""}
                      control={control}
                      name={`products.${index}.status`}
                      label="Status"
                      options={CONTRACTSTATUS}
                      error={errors.products && errors.products[index]?.status}
                    />
                  </Grid>
                </Grid>
                <TextField
                  error={
                    !!(errors.products && errors.products[index]?.description)
                  }
                  multiline
                  rows={4}
                  label="Description"
                  {...register(`products.${index}.description`, {
                    required: "Description is required",
                  })}
                  variant="outlined"
                  helperText={
                    (errors.products
                      && errors.products[index]?.description?.message)
                    || ""
                  }
                  style={{ ...style, width: "100%", marginBottom: "16px" }}
                />
                <Controller
                  name={`products.${index}.documents`}
                  control={control}
                  defaultValue={field.documents || []}
                  rules={{
                    validate: {
                      maxFiles: (documents: FileInterface[]) => documents.length <= 3
                        || "Number of files should not exceeds 3",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Dropzone
                      error={
                        errors.products
                        && errors.products[index]
                        && errors.products[index]?.documents
                      }
                      files={value || []}
                      onChange={onChange}
                      onRemove={(id: string) => setRemovedDocs((prev) => [...prev, id])}
                    />
                  )}
                />
              </div>
            </React.Fragment>
          );
        })}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={2}
        >
          <Button
            disabled={loading || docUploading}
            variant="outlined"
            onClick={() => reset()}
          >
            Clear
          </Button>
          <Button variant="outlined" onClick={() => onPrevious(getValues())}>
            Previous
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading || docUploading}
            startIcon={
              (loading || docUploading) && (
                <CircularProgress size={20} color="inherit" />
              )
            }
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

ProductForm.defaultProps = {
  initialValues: [],
};

export default ProductForm;
