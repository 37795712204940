import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VendorInterface, { ContactInterface } from './interface';
import { PREFERREDCONTACT } from '../../../utils/constants';
import RadioInput from '../../../components/RadioInput';

interface ContactFormInterface {
  loading: boolean;
  onSubmit: (data: VendorInterface) => Promise<void>;
  onPrevious: (data: VendorInterface) => void;
  initialValues?: ContactInterface[];
}

const ContactForm: React.FC<ContactFormInterface> = ({
  onSubmit,
  onPrevious,
  initialValues = [],
}) => {
  const defaultContact = {
    name: '',
    title: '',
    phone: '',
    email: '',
    isPrimary: false,
    preferred: '',
  };
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<VendorInterface>({
    defaultValues: {
      contacts: initialValues.length ? initialValues : [defaultContact],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
    keyName: "uid",
  });
  const [primaryContactIndex, setPrimaryContactIndex] = useState<number | null>(null);
  const handlePrimaryContactChange = (index: number) => {
    setPrimaryContactIndex(primaryContactIndex === index ? null : index);
  };

  const [removedContacts, setRemovedContacts] = useState<string[]>([]);

  const handleSubmitForm = (data: VendorInterface) => {
    const payload = {
      ...data,
      removedContacts,
    };
    onSubmit(payload);
  };
  const textboxStyle = { width: 'auto', minHeight: "80px" };
  const iconStyle = { height: "50px", width: "50px" };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="left"
      spacing={2}
      marginTop={5}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {fields.map((field, index) => {
          const isPrimary = primaryContactIndex === index;
          return (
            <React.Fragment key={field.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
              >
                <h4>{`Contact: ${index + 1}`}</h4>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <IconButton
                    style={iconStyle}
                    onClick={() => {
                      const { id: contactId } = field;
                      if (contactId) {
                        setRemovedContacts((prev) => [...prev, contactId]);
                      }
                      remove(index);
                    }}
                    aria-label="delete"
                    size="small"
                    disabled={fields.length === 1}
                  >
                    <RemoveIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton style={iconStyle} onClick={() => append(defaultContact)} aria-label="delete" size="small">
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    style={textboxStyle}
                    error={!!errors?.contacts?.[index]?.name}
                    helperText={errors?.contacts?.[index]?.name?.message || ''}
                    {...register(`contacts.${index}.name`, {
                      required: "Full Name is required",
                      pattern: {
                        value: /^[A-Za-z\s-]+$/,
                        message: "Full Name should contain only alphabets and spaces",
                      },
                    })}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    style={textboxStyle}
                    error={!!errors?.contacts?.[index]?.email}
                    helperText={errors?.contacts?.[index]?.email?.message || ''}
                    {...register(`contacts.${index}.email`, {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Invalid email address',
                      },
                    })}
                  />
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={isPrimary}
                        onChange={() => handlePrimaryContactChange(index)}
                      />
                    )}
                    label="Primary Contact"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    label="Title"
                    variant="outlined"
                    style={textboxStyle}
                    error={!!errors?.contacts?.[index]?.title}
                    helperText={errors?.contacts?.[index]?.title?.message || ''}
                    {...register(`contacts.${index}.title`, { required: "Title is required" })}
                  />
                  <TextField
                    label="Phone Number"
                    type="number"
                    variant="outlined"
                    style={textboxStyle}
                    error={!!errors?.contacts?.[index]?.phone}
                    helperText={errors?.contacts?.[index]?.phone?.message || ''}
                    {...register(`contacts.${index}.phone`, {
                      required: "Phone Number is required",
                      pattern: {
                        value: /^(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/,
                        message: "Please enter valid phone number",
                      },
                    })}
                  />
                  <RadioInput
                    name={`contacts.${index}.preferred`}
                    control={control}
                    label="Preferred Contact"
                    options={PREFERREDCONTACT}
                    error={errors.contacts && errors.contacts[index]?.preferred}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={2}
        >
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              setPrimaryContactIndex(null);
            }}
          >
            Clear
          </Button>
          <Button variant="outlined" onClick={() => onPrevious(getValues())}>Previous</Button>
          <Button type="submit" variant="contained">Next</Button>
        </Stack>
      </form>
    </Stack>
  );
};

ContactForm.defaultProps = {
  initialValues: [],
};

export default ContactForm;
