import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import AddVendor from './pages/AddVendor';
import VendorListings from './pages/VendorListings';
import Footer from './components/Footer';
import withAuthentication from './utils/withAuthentication';
import { RootState } from './store';
import Loader from './components/Loader';
import EditVendor from './pages/EditVendor';
import VendorDetail from './pages/VendorDetail';
import ProductDetail from './pages/ProductDetail';
import Dashboard from './pages/Dashboard';

const App = () => {
  const { loading: userLoading } = useSelector(
    (state: RootState) => state.userActions,
  );
  return (
    <>
      {userLoading && <Loader loading={userLoading} />}
      {!userLoading && (
      <>
        <Header />
        <div className="body-container">
          <Routes>
            <Route path="/" element={<VendorListings />} />
            <Route path="/add" element={<AddVendor />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/edit/:vendorId" element={<EditVendor />} />
            <Route path="/vendor/:vendorId" element={<VendorDetail />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
          </Routes>
        </div>
        <Footer />
      </>
      )}
    </>
  );
};

export default withAuthentication(App);
