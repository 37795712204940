
import React, { useEffect, useMemo, useState } from 'react';
import {
  Chip,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
} from "@mui/material";
import { convertCamelCaseToUpperCase, getColumns } from '../utils/utils';
import { ObjectInterface } from '../store/vendor/index.type';

interface DataTableInterface {
  columns?: string[],
  labelField?: string,
  data: ObjectInterface[],
  onRowClick?: (data: ObjectInterface) => void
}

const DataTable = ({
  columns, labelField, data, onRowClick,
}: DataTableInterface) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<ObjectInterface[]>([]);

  const resetPagination = () => {
    setPage(0);
    setRowsPerPage(5);
    setRows([]);
  };

  useEffect(() => {
    resetPagination();
  }, [data]);

  useEffect(() => {
    if (data) {
      const pData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      setRows(pData);
    }
  }, [data, page, rowsPerPage]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cols = columns?.length
    ? columns
    : useMemo(() => data && getColumns(data, ['createdDate', 'updatedDate', labelField || '']), [data]);
  const [firstColumn] = cols;

  return (
    <TableContainer style={{ margin: 20, width: '100%' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell key={col}>
                {convertCamelCaseToUpperCase(col).toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow onClick={() => onRowClick && onRowClick(row)} hover key={row.id}>
              {cols.map((col: string) => {
                return (
                  <TableCell key={col}>
                    {row[col] && col.toLowerCase().includes('date') ? (new Date(row[col]).toLocaleDateString()) : row[col]}
                    {labelField && col === firstColumn && row[labelField]
                    && (
                    <Chip
                      style={{ marginLeft: 10 }}
                      label="primary"
                      color="primary"
                      size="small"
                    />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length >= 5
      && (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      )}
    </TableContainer>
  );
};

DataTable.defaultProps = {
  columns: [],
  labelField: '',
  onRowClick: undefined,
};

export default DataTable;
