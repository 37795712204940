import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/theme";

const StyledHeader = styled.div`
  height: 60px;
  padding: 0 35px;
  background-color: ${Colors.background};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.grey20};
  position: relative;
  .mobile {
    display: none;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding: 0px 16px;
    height: 60px;
    .mobile {
      display: flex;
      align-items: center;
    }
    .profile-dropdown-mobileview {
      width: 375px;
      height: 52px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      border: none;
      background-color: ${Colors.grey05};
      padding: 12px 24px;
      font-family: ${FontFamily.sourceSansPro};
      font-size: 18px;
      font-weight: 600;
      color: ${Colors.text01};
    }
  }
`;

const NavLeft = styled.div`
  width: 25.3rem;
  height: 35px;
  display: flex;
  align-items: center;
  gap: 9px;
  .hamburger,
  .mobile-globalHeader {
    display: none;
  }
  .header-container {
      display: flex;
      background: none;
      border: none;
      cursor: pointer;
  }
  .header-title {
    width: 14vw;
    text-align: start;
    font-size: 25px;
    padding-top: 5px;
    font-weight: Bold;
    color: ${Colors.white01};
    background-color: ${Colors.background};
    font-family: ${FontFamily.sourceSansPro};
    &:hover {
      color:${Colors.text01};
    }
  }
  @media (max-width: ${BreakPoints.LargeDevices}) and (max-width: ${BreakPoints.ExtraLargeDevices}) {
    .header-title {
      width: 36vw;
      font-size:25px;
    }
  }
  @media (min-width: ${BreakPoints.MediumDevices}) and (max-width: ${BreakPoints.LargeDevices}) {
    .header-title {
      width: 24vw;
      font-size: 25px;
    }
  }
  @media (max-width: ${BreakPoints.SmallDevices}) {
    .hamburger {
      display: block;
      width: 1.5rem;
      padding-top: 2px;
      height: 1.5rem;
      color: ${Colors.white01};
    }
    .header-container {
      display: flex;
      padding: 0.05px;
    }
    .menu {
      position: fixed;
      inset: 0px 32px 0px 0px;
      padding-bottom: 48px;
      background-color: ${Colors.lightSurface};
      z-index: 1005;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .mobilemenu-title {
      display: flex;
      justify-content: space-between;
      padding: 16px 8px 16px 16px;
      border-bottom: 0.3px solid ${Colors.grey20};
      font-family: ${FontFamily.sourceSansPro};
      font-size: 25px;
      font-weight: 600;
      background-color: ${Colors.background};
      text-align: left;
      color: ${Colors.white01};
      &:hover{
        color: ${Colors.text01};
      }
    }
    .header-title {
      width: 38vw;
      font-size: 21px;
      color: ${Colors.white01};
      padding-left: 1px;
      padding-top: 2px;
    }
    .mobile-globalHeader {
      display: block;
      position: fixed;
      inset: 0px 0px 0px 0px;
      background-color: ${Colors.grey20};
      z-index: 100;
    }
  }
`;
const NavRight = styled.div`
  height: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  position: relative;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
  }
  .nav-menu {
    font-family: ${FontFamily.sourceSansPro};
    font-size: 16px;
    align-items: center;
    display: flex;
    font-weight: 600;
    color:${Colors.white01};
    text-decoration: none;
    margin-left: 10px;
    &:hover {
      color:${Colors.text01};
    }
  }
`;

export { StyledHeader, NavLeft, NavRight };
