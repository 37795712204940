import React from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Colors } from "./theme";

const ENV: string | undefined = process.env.NODE_ENV;
const AUTHAPIURL: string | undefined = process.env.REACT_APP_AUTH_API;
const AUTHURL: string | undefined = process.env.REACT_APP_AUTH_URL;
const DATAAPIURL: string | undefined = process.env.REACT_APP_DATA_API;

const DOMAIN: string | undefined = process.env.REACT_APP_DOMAIN;

const AWSACCESSKEY: string | undefined = process.env.REACT_APP_AWS_ACCESS_KEY;
const AWSSECRETKEY: string | undefined = process.env.REACT_APP_AWS_SECRET_KEY;
const AWSREGION: string | undefined = process.env.REACT_APP_AWS_REGION;
const AWSBUCKET: string | undefined = process.env.REACT_APP_AWS_BUCKET;

const APIHEADERS = {
  Accept: 'application/json',
};
const FOOTERCOPYWRITE = "© 2023 Sastah. All rights reserved.";
const CONTRACTSTATUS = ['Active', 'Up for renewal', 'Not Renewed', 'Notice Served', 'Terminated', 'Expired'];
const BUSINESSTYPE = ['Technology and Software Providers', 'Payment Processors and Card Issuers', 'Outsourced Services Providers', 'Security and Compliance Services', 'Marketing and Communication Services', 'Insurance Providers', 'Transportation and Logistics Services'];

const RISK = [
  { label: 'Critical', icon: <KeyboardDoubleArrowUpIcon style={{ color: Colors.red }} /> },
  { label: 'High', icon: <KeyboardArrowUpIcon style={{ color: Colors.lightred }} /> },
  { label: 'Moderate', icon: <DragHandleIcon style={{ color: Colors.orange }} /> },
  { label: 'Low', icon: <KeyboardArrowDownIcon style={{ color: Colors.blue }} /> },
];

const PREFERREDCONTACT = ['Email', 'Phone'];
const LICENSETYPE = ['Perpetual', 'Subscription', 'Feature Licensing', 'Device Licensing', 'OpenSource', 'SaaS'];
const RISKCHARTCOLORS = [
  { label: 'Critical', color: '#FF8042' },
  { label: 'Moderate', color: '#00C49F' },
  { label: 'High', color: '#FFBB28' },
  { label: 'Low', color: '#0088FE' },
];
const CATEGORY = [
  'Compliance, Legal and Audit',
  'BSA and AML',
  'Banking Operations',
  'Risk Assessment',
  'Core Banking',
  'Digital Banking',
  'Data and Analytics',
  'Fintech Innovation',
  'Payment Processing',
  'Customer Relationship Management (CRM)',
  'Loan Origination and Management',
  'Treasury and Cash Management',
  'Integration and Middleware',
  'Fraud Prevention and Security',
];
export {
  AUTHURL,
  AUTHAPIURL,
  DATAAPIURL,
  DOMAIN,
  ENV,
  AWSACCESSKEY,
  AWSSECRETKEY,
  AWSREGION,
  AWSBUCKET,
  APIHEADERS,
  FOOTERCOPYWRITE,
  CONTRACTSTATUS,
  BUSINESSTYPE,
  RISK,
  PREFERREDCONTACT,
  LICENSETYPE,
  RISKCHARTCOLORS,
  CATEGORY,
};
