import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { ObjectInterface } from '../../../store/vendor/index.type';
import {
  generateRandomKey,
  getFields, formatTitle, formatValue,
} from '../../../utils/utils';

const Profile = ({ vendor }: ObjectInterface) => {
  const vFields = getFields(vendor);
  return (
    <Box
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        maxWidth: '800px',
      }}
    >
      <TableContainer style={{ margin: 20, width: 'auto' }} component={Paper}>
        <Table>
          <TableBody>
            {Object.keys(vFields).map((key) => (
              <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell className="label">
                  {formatTitle(key)}
                </TableCell>
                <TableCell className="label">
                  {formatValue(key, vFields[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Profile;
