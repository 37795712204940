import React, { useState } from "react";
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Popover,
  Box,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const FilterMenu = ({
  filters: menuFilters,
  selectedFilters: initialSelectedFilters,
  handleApplyFilters,
}: {
  filters: string[];
  selectedFilters: string[];
  handleApplyFilters: (filters: string[]) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [localSelectedFilters, setLocalSelectedFilters] = useState<string[]>(
    initialSelectedFilters,
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (filter: string) => {
    const index = localSelectedFilters.indexOf(filter);
    if (index === -1) {
      setLocalSelectedFilters([...localSelectedFilters, filter]);
    } else {
      setLocalSelectedFilters([
        ...localSelectedFilters.slice(0, index),
        ...localSelectedFilters.slice(index + 1),
      ]);
    }
  };

  const handleApply = () => {
    handleApplyFilters(localSelectedFilters);
    handleClose();
  };

  const handleCancel = () => {
    setLocalSelectedFilters(initialSelectedFilters);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        aria-label="filter list"
        title={`Selected: ${localSelectedFilters.length}`}
        style={{ color: "white" }}
      >
        <Badge badgeContent={localSelectedFilters.length} color="secondary">
          <FilterListIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Filters</FormLabel>
            <FormGroup>
              {menuFilters.map((filter) => (
                <FormControlLabel
                  key={filter}
                  control={(
                    <Checkbox
                      checked={localSelectedFilters.includes(filter)}
                      onChange={() => handleFilterChange(filter)}
                    />
              )}
                  label={filter}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Box mt={2} textAlign="right">
            <Button variant="contained" onClick={handleApply} sx={{ mr: 1 }}>
              Apply
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default FilterMenu;
