import "../index.css";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button, Grid, Stack, TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Dropdown from "../../../components/Select";
import { BUSINESSTYPE, RISK } from "../../../utils/constants";
import VendorInterface from "./interface";
import countriesData from '../../../utils/country.json';

interface VendorFormProps {
  onSubmit: (data: VendorInterface) => Promise<void>;
  initialValues?: VendorInterface;
}

interface Country {
  iso: string;
  country: string;
  regex: string;
}

const VendorForm: React.FC<VendorFormProps> = ({
  onSubmit,
  initialValues = {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<VendorInterface>({ defaultValues: initialValues });

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  useEffect(() => {
    if (initialValues.country) {
      const foundCountries = countriesData.filter(
        (country) => country.iso === initialValues.country,
      );
      const foundCountry = foundCountries.length > 0 ? foundCountries[0] : null;
      setSelectedCountry(foundCountry);
    }
  }, [initialValues.country]);
  const handleFormSubmit = async (data: VendorInterface) => {
    if (selectedCountry) {
      data.country = selectedCountry.iso;
    }
    await onSubmit(data);
  };
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="left"
      spacing={2}
      marginTop={5}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} className="vendor-form">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              error={!!errors.name}
              id="name"
              label="Vendor Name"
              size="medium"
              {...register("name", {
                required: "Vendor Name is required",
                pattern: {
                  value: /^[A-Za-z\d][A-Za-z\d\s,.'_-]*[A-Za-z\d\s.'-]$/,
                  message: "Vendor Name should start with a letter or digit and may contain letters, digits, spaces, commas, periods, apostrophes, hyphens, and underscores",
                },
              })}
              variant="outlined"
              helperText={(errors.name && errors.name.message) || ""}
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.alternativeName}
              id="alternativeName"
              label="Alternative Name"
              size="medium"
              {...register("alternativeName", {
                pattern: {
                  value: /^[A-Za-z\d][A-Za-z\d\s,.'_-]*[A-Za-z\d\s.'-]$/,
                  message: "Alternative Name should start with a letter and may contain letters, spaces, hyphens, underscores, and optional digits",
                },
              })}
              variant="outlined"
              helperText={
                (errors.alternativeName && errors.alternativeName.message) || ""
              }
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.feinNumber}
              id="feinNumber"
              label="FEIN Number (XX-XXXXXXX)"
              {...register("feinNumber", {
                required: "FEIN Number is required",
                pattern: {
                  value: /^\d{2}-\d{7}$/,
                  message: "Please enter valid FEIN number",
                },
              })}
              variant="outlined"
              helperText={
                (errors.feinNumber && errors.feinNumber.message) || ""
              }
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.addressLine1}
              id="addressLine1"
              label="Address Line 1"
              {...register("addressLine1", {
                required: "Address line one is required",
              })}
              variant="outlined"
              helperText={
                (errors.addressLine1 && errors.addressLine1.message) || ""
              }
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.city}
              id="city"
              label="City"
              {...register("city", {
                required: "City is required",
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "City name should contain only letters",
                },
              })}
              variant="outlined"
              helperText={(errors.city && errors.city.message) || ""}
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.zip}
              type="number"
              id="zip"
              label="Zip"
              {...register("zip", {
                required: "Zip code is required",
                pattern: {
                  value: new RegExp(
                    selectedCountry?.regex || "",
                  ),
                  message: "Invalid zip code",
                },
              })}
              variant="outlined"
              helperText={(errors.zip && errors.zip.message) || ""}
              style={{ width: "auto", minHeight: "80px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Dropdown
              defaultValue={initialValues.risk}
              name="risk"
              control={control}
              label="Risk"
              options={RISK}
              error={errors.risk}
            />
            <Dropdown
              defaultValue={initialValues.type}
              name="type"
              control={control}
              label="Type"
              options={BUSINESSTYPE}
              error={errors.type}
            />
            <TextField
              error={!!errors.website}
              id="website"
              label="Website"
              {...register('website', {
                required: "Country is required",
                pattern: {
                  value: /^(?:(ftp|http[s]?):\/\/[^ "]+)|(https?:\/\/)?(?:www\.)?[\w-]+\.\w{2,}(?:\/\S*)?$/,
                  message: "Please enter a valid website URL",
                },
              })}
              variant="outlined"
              helperText={(errors.website && errors.website.message) || ""}
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.addressLine2}
              id="addressLine2"
              label="Address Line 2"
              {...register("addressLine2")}
              variant="outlined"
              helperText={
                (errors.addressLine2 && errors.addressLine2.message) || ""
              }
              style={{ width: "auto", minHeight: "80px" }}
            />
            <TextField
              error={!!errors.state}
              id="state"
              label="State"
              {...register("state", {
                required: "State is required",
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "State name should contain only letters",
                },
              })}
              variant="outlined"
              helperText={(errors.state && errors.state.message) || ""}
              style={{ width: "auto", minHeight: "80px" }}
            />
            <Autocomplete
              id="country"
              options={countriesData}
              autoHighlight
              getOptionLabel={(option) => `${option.country} (${option.iso})`}
              renderOption={(props: any, option: Country) => (
                <li {...props}>{option.country}</li>
              )}
              value={selectedCountry}
              onChange={(event, newValue) => setSelectedCountry(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  variant="outlined"
                  error={!!errors.country}
                  helperText={(errors.country && errors.country.message) || ""}
                  {...register("country", { required: "Country is required" })}
                />
              )}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={2}
        >
          <Button variant="outlined" onClick={() => reset()}>
            Clear
          </Button>
          <Button type="submit" variant="contained">
            Next
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

VendorForm.defaultProps = {
  initialValues: {
    name: "",
    alternativeName: "",
    risk: "",
    type: "",
    address: "",
    feinNumber: "",
    website: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    contacts: [],
    products: [],
  },
};

export default VendorForm;
