import React, { useState } from 'react';
import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { ObjectInterface } from '../../../store/vendor/index.type';
import {
  generateRandomKey,
  getColumns,
  getFields, formatTitle, formatValue,
} from '../../../utils/utils';
import DataTable from '../../../components/table';

const Products = ({ products }: ObjectInterface) => {
  const defaultSelection = products && products.length && products[0];
  const [selected, setSelected] = useState<ObjectInterface>(defaultSelection);

  const columns = products
    && getColumns(products, ['updatedDate', 'createdDate', 'startDate', 'endDate', 'licenseType', 'licenseCount', 'category', 'description', 'isDeleted']);

  const handleRowClick = (data: ObjectInterface) => setSelected(data);

  return (
    <Box
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <DataTable columns={columns} data={products} onRowClick={handleRowClick} />
        </Grid>
        {selected && (
          <Grid item xs={12} sm={12} md={8}>
            <TableContainer style={{ margin: 20, width: 'auto' }} component={Paper}>
              <Table>
                <TableBody>
                  {Object.keys(getFields(selected)).map((key) => (
                    <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <TableCell className="label">
                        {formatTitle(key)}
                      </TableCell>
                      <TableCell className="label">
                        {formatValue(key, selected[key])}
                      </TableCell>
                    </TableRow>
                  ))}
                  {selected.documents && selected.documents.length
                    ? (
                      <>
                        <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell rowSpan={selected.documents.length + 1} className="label">
                            DOCUMENTS
                          </TableCell>
                        </TableRow>
                        {selected.documents.map(({ url, name }: any) => (
                          <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'none' } }}>
                            <TableCell className="label">
                              <Link href={url}>
                                {name.replaceAll("+", " ")}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )
                    : <div />}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Products;
