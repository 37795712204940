import { ObjectInterface } from "../store/vendor/index.type";

const formatUrl = (url: string) => {
  return url.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '').replace(/\s+/g, '-').toLowerCase();
};

const convertCamelCaseToUpperCase = (key: string) => {
  key = key.replace(/([a-z])([A-Z])/g, '$1 $2');
  key = key.replace(/([^0-9])(\d)/g, '$1 $2');
  key = key.replace(/^./, (str: string) => str?.toUpperCase());

  return key;
};

const replaceNullWithHyphen = (value: string) => {
  return (value !== null && value !== undefined) ? value : "—";
};
const replaceNullWithNotAvailable = (value: string) => {
  return (value !== null && value !== undefined) ? value : "N/A";
};

const formatPhoneNumber = (value: string) => {
  return value.replace(/(\d{3})(\d{3})?(\d{0,4})$/, (match, p1, p2, p3) => {
    let formatted = `${p1}`;
    if (p2) {
      formatted += `-${p2}`;
    }
    if (p3) {
      formatted += p3.length === 4 ? `-${p3}` : p3;
    }
    return formatted;
  });
};

const formatTableRows = (params: object) => {
  const rows = Object.entries(params)?.map(([key, value]) => ({
    Key: key,
    value: value !== null ? value : "—",
  }));
  return rows;
};

const isPrimitive = (value: any) => {
  return (
    typeof value === 'string'
    || typeof value === 'number'
    || typeof value === 'boolean'
    || value === null
    || typeof value === 'undefined'
  );
};
const isUUID = (value: string) => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(value);
};

const convertToDateFormat = (str: string) => {
  const datetime = new Date(str);
  const isValidDate = str && !Number.isNaN(datetime.getTime()) && str.trim() !== '';
  if (isValidDate) {
    const year = datetime.getFullYear();
    const month = datetime.getMonth() + 1;
    const day = datetime.getDate();
    const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    return dateString;
  }
  return str;
};

const generateRandomKey = () => Math.random().toString(36).substring(7);

const getColumns = (array: ObjectInterface[], excludeFields: string[]): string[] => {
  const keys: string[] = [];
  array.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)
        && isPrimitive(obj[key])
        && !excludeFields.includes(key)
        && !keys.includes(key)
        && !isUUID(obj[key])) {
        keys.push(key);
      }
    });
  });

  return keys;
};

const formatAddress = ({
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  zip,
}: ObjectInterface) => [
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  zip,
]
  .filter((value) => value !== undefined)
  .join(', ');

const getFields = (data: ObjectInterface) => {
  const excludeFields = ["updatedDate", "createdDate", "isDeleted"];
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    zip,
    ...rest
  } = data;
  const newData = Object.fromEntries(
    Object.entries(rest)
      .filter(
        ([key, value]) => isPrimitive(value) && !isUUID(value) && !excludeFields.includes(key),
      ),
  );
  const address = formatAddress({
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    zip,
  });
  if (address) newData.address = address;
  return newData;
};

const formatValue = (key: string, value: string) => (key.toLowerCase().includes('date') && value
  ? convertToDateFormat(value)
  : replaceNullWithHyphen(value));

const formatTitle = (val: string) => val && convertCamelCaseToUpperCase(val).toUpperCase();

export {
  formatUrl, convertCamelCaseToUpperCase, formatPhoneNumber,
  formatTableRows, replaceNullWithHyphen, replaceNullWithNotAvailable,
  isUUID, isPrimitive, convertToDateFormat,
  generateRandomKey, getColumns,
  getFields, formatTitle, formatValue, formatAddress,
};
