import React, { ReactElement, ReactNode } from "react";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill, PiNumberCircleThreeFill } from "react-icons/pi";
import { StyledTitle, StyledProgressBar } from "./index.styles";

interface ProgressBarProps {
  children: ReactElement<any, any> | ReactNode,
  currentStep: number,
}

const ProgressBar = ({
  children, currentStep,
}: ProgressBarProps) => {
  return (
    <StyledProgressBar>
      <div className="steps">
        <StyledTitle data-testid="steps" className={`step-name ${currentStep >= 1 && 'completed'}`}>
          <PiNumberCircleOneFill />
          <span className="title-text">Vendor Details</span>
        </StyledTitle>
        <StyledTitle data-testid="steps" className={`step-name ${currentStep >= 2 && 'completed'}`}>
          <PiNumberCircleTwoFill />
          <span className="title-text">Contact Details</span>
        </StyledTitle>
        <StyledTitle data-testid="steps" className={`step-name ${currentStep >= 3 && 'completed'}`}>
          <PiNumberCircleThreeFill />
          <span className="title-text">Product Details</span>
        </StyledTitle>
      </div>
      {children}

    </StyledProgressBar>
  );
};

export default ProgressBar;
