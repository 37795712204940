import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../api/data";
import { VendorInterface } from "./index.type";

const fetchAsyncVendors :AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk(
  "vendor/fetchAsyncVendors",
  get,
);

const initialState: VendorInterface = {
  loading: false,
  status: 0,
  error: {},
  vendors: [],
  count: 0,
  payload: {
    offset: 0,
    limit: 1000,
  },
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncVendors.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncVendors.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        vendors: payload.rows,
        count: payload.count,
        status: payload.status,
        loading: false,
      };
    },
    [fetchAsyncVendors.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

const vendorActions = vendorSlice.actions;

export {
  fetchAsyncVendors,
  vendorActions,
};
export default vendorSlice.reducer;
