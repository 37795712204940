import "../index.css";
import React, { useEffect, useState, useReducer } from "react";
import { Alert } from "@mui/material";
import { post } from "../../../api/data";
import ProgressBar from "../../../components/FormCard";
import VendorForm from "./vendor";
import ContactForm from "./contact";
import ProductForm from "./product";
import { reducer } from "../../../store/state";
import VendorInterface from "./interface";

interface FormInterface {
  data?: VendorInterface;
  onSuccess?: (val: boolean) => void;
}

const Form = ({ data: vendorData, onSuccess }: FormInterface) => {
  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState<VendorInterface | undefined>(
    vendorData,
  );
  const initialState = {
    error: "",
    loading: false,
    success: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { error, loading } = state;

  const resetForm = () => {
    setFormStep(1);
    setFormData(undefined);
    dispatch({ type: "SET_SUCCESS", payload: false });
  };

  const onSubmit = async (data: VendorInterface) => {
    dispatch({ type: "SET_LOADING", payload: true });
    const res = await post({
      endpoint: "vendor",
      apiPayload: data,
    });
    const { products: newProducts } = res;
    if (newProducts.length > 0) {
      dispatch({ type: "SET_SUCCESS", payload: true });
      if (onSuccess) onSuccess(true);
      resetForm();
      dispatch({ type: "SET_ERROR", payload: "" });
    } else {
      dispatch({ type: "SET_ERROR", payload: res?.data });
    }
    dispatch({ type: "SET_LOADING", payload: false });
  };

  const next = async (data: VendorInterface) => {
    setFormData({ ...formData, ...data });
    setFormStep((currentStep) => currentStep + 1);
  };

  useEffect(() => {
    if (formStep > 3 && formData) onSubmit(formData);
  }, [formStep, formData]);

  const initialVendorData = vendorData || formData;
  return (
    <>
      {!loading && error && (
        <Alert severity="error">
          Something went wrong. Please try again later.
        </Alert>
      )}
      <ProgressBar currentStep={formStep} data-testid="form-card">
        {formStep === 1 && (
          <VendorForm onSubmit={next} initialValues={initialVendorData} />
        )}
        {formStep === 2 && (
          <ContactForm
            onSubmit={next}
            onPrevious={() => setFormStep((prevStep) => prevStep - 1)}
            initialValues={initialVendorData?.contacts}
            loading={false}
          />
        )}
        {formStep >= 3 && (
          <ProductForm
            onSubmit={next}
            onPrevious={() => setFormStep((prevStep) => prevStep - 1)}
            initialValues={initialVendorData?.products}
            loading={loading}
          />
        )}
      </ProgressBar>
    </>
  );
};

Form.defaultProps = {
  data: undefined,
  onSuccess: () => null,
};

export default Form;
