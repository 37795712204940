import './style.css';
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Stack,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
  DialogContent,
  Link,
} from '@mui/material';
import { fetchAsyncVendors } from '../../store/vendor';
import Breadcrumb from '../../components/Breadcrumb';
import Listing from './listing';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import { reducer } from '../../store/state';
import { remove } from '../../api/data';
import SearchComponent from '../../components/Search';
import Dropzone, { FileInterface } from '../../components/FileUpload';
import { generatePresignedUrl, uploadFileWithPresignedUrl } from '../../api/s3';
import { AWSBUCKET, AWSREGION } from '../../utils/constants';

interface ImportDialogInterface {
  open: boolean;
  onClose: () => void;
}

const ImportDialog = ({
  open, onClose,
}: ImportDialogInterface) => {
  const templateURL = `https://${AWSBUCKET}.s3.${AWSREGION}.amazonaws.com/bulk/template.xlsx`;
  const { user: { parentId: fiId } } = useSelector(
    (state: RootState) => state.userActions,
  );
  const [file, setFile] = useState<FileInterface>();
  const initialUploadState = {
    error: '',
    loading: false,
    success: false,
  };
  const [uploadState, dispatchUpload] = useReducer(reducer, initialUploadState);
  const { loading, error, success } = uploadState;
  const uploadFile = async () => {
    dispatchUpload({ type: 'SET_LOADING', payload: true });
    try {
      if (file) {
        const signedUrl = await generatePresignedUrl(`bulk/${fiId}/${file.name}`, file.type);
        await uploadFileWithPresignedUrl(signedUrl, file);
        dispatchUpload({ type: 'SET_SUCCESS', payload: true });
      }
    } catch (err) {
      dispatchUpload({
        type: 'SET_ERROR',
        payload: `${err}`,
      });
    }
    dispatchUpload({ type: 'SET_LOADING', payload: false });
  };

  const close = () => {
    dispatchUpload({ type: 'SET_LOADING', payload: false });
    dispatchUpload({ type: 'SET_SUCCESS', payload: false });
    dispatchUpload({ type: 'SET_ERROR', payload: '' });
    setFile(undefined);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        margin={5}
      >
        {!loading && error
            && (
            <Alert
              style={{ marginBottom: 10 }}
              severity="error"
              onClose={close}
            >
              Something went wrong. Please try again later.
            </Alert>
            )}
        {!loading && success
            && (
            <Alert
              style={{ marginBottom: 10 }}
              onClose={close}
              severity="success"
            >
              Vendor Import is in progress.
              You will notified in the email once the process is completed.
              Or you can check here after few minutes.
            </Alert>
            )}
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          Import Vendors
        </DialogTitle>
        <DialogContent>
          Please refer the
          {' '}
          <Link href={templateURL}>template</Link>
          .
        </DialogContent>
        <Dropzone
          files={file ? [file] : []}
          onChange={(files) => setFile(files[files.length - 1])}
          fileFormat={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
            "application/vnd.ms-excel": [".xls"],
          }}
        />
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!file || loading || success || !!error}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
            onClick={uploadFile}
          >
            Process
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const VendorListings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isImport, setImport] = useState(false);
  const {
    loading,
    vendors,
    payload:
    { limit, offset },
  } = useSelector((state: RootState) => state.vendorActions);
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get('query');
  const [breadcrumb, setBreadcrumb] = useState([
    {
      displayName: 'Smartvendor',
      url: `/`,
    },
  ]);
  useEffect(() => {
    dispatch(fetchAsyncVendors(`vendors?limit=${limit}&offset=${offset}`));
  }, []);

  const initialState = {
    error: '',
    loading: false,
    success: false,
  };
  const [deleteState, dispatchDelete] = useReducer(reducer, initialState);
  const { loading: deleteLoading, error: deleteError, success: deleteSuccess } = deleteState;

  const deleteVendor = async (id: string) => {
    dispatchDelete({ type: 'SET_LOADING', payload: true });
    const res = await remove(`vendor/${id}`);
    if (res) {
      dispatchDelete({ type: 'SET_SUCCESS', payload: true });
      dispatchDelete({ type: 'SET_ERROR', payload: '' });
      dispatch(fetchAsyncVendors(`vendors?limit=${limit}&offset=${offset}`));
    } else {
      dispatchDelete({ type: 'SET_ERROR', payload: res?.data });
    }
    dispatchDelete({ type: 'SET_LOADING', payload: false });
  };
  useEffect(() => {
    if (searchTerm && !breadcrumb.some((item) => item.displayName === 'Search')) {
      setBreadcrumb((prev) => [...prev, {
        displayName: 'Search',
        url: '/',
      }]);
    }
  }, [searchTerm, breadcrumb]);
  return (
    <div className="">
      <ImportDialog
        open={isImport}
        onClose={() => setImport(false)}
      />
      <div className="">
        <Breadcrumb navigation={breadcrumb} />
        {loading ? <Loader loading={loading} />
          : (
            <>
              {!deleteLoading && deleteError
            && <Alert style={{ marginBottom: 10 }} severity="error">Something went wrong. Please try again later.</Alert>}
              {!deleteLoading && deleteSuccess
            && <Alert style={{ marginBottom: 10 }} onClose={() => dispatchDelete({ type: 'SET_SUCCESS', payload: false })} severity="success">Vendor has been removed successfully.</Alert>}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                {vendors && vendors.length
                  ? (
                    <>
                      <SearchComponent options={vendors} />
                      <Stack
                        direction="row"
                        spacing={2}
                      >
                        <Button variant="outlined" onClick={() => setImport(true)}>Import Vendors</Button>
                        <Button variant="outlined" onClick={() => navigate(`/add`)}>Add Vendor</Button>
                      </Stack>
                    </>
                  )
                  : <div />}
              </Stack>
              <Listing deleteVendor={deleteVendor} />
            </>
          )}
      </div>
    </div>
  );
};

export default VendorListings;
