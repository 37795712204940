import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { BreakPoints, Colors } from "../../utils/theme";

const StyledSearchContent = styled(Link)`
    align-items: start;
    justify-content: start;
    background-color: ${Colors.lightSurface};
    cursor: pointer;
    padding: 1em;
    align-items: start;
    text-decoration: none;
    &:hover {
        background-color: ${Colors.subduedSurface};
    }
`;

const StyledSeeMore = styled(Button)`
    background-color: white;
    display: flex;
    justify-content: left;
    cursor: pointer;
    padding: 1em;
    align-items: start;
    text-decoration: none;
    &:hover {
        background-color: ${Colors.subduedSurface};
    }
`;

const StyledSearchBarContainer = styled.div`
    width: 50%;
    position: relative;
    label {
        background-color: ${Colors.lightSurface};
        padding: 0 5px;
    }
    .search {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        padding-left: 10px;
        font-weight: 400;
    }
    .link-text {
        text-decoration: none;
    }
    .name-text {
        color: ${Colors.darkGrey};
        font-weight: 600;
        font-size: 14px;
    }
    .no-result {
        padding: 1%;
        font-size: 14px;
        background-color: ${Colors.white};
        color: ${Colors.darkGrey};
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
        width: 100%;
        margin-bottom: 0%;
    }
`;

const StyledSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 80;
    width: 100%;
    box-shadow: 0 1px 2px 0 ${Colors.boxShadow};
    .selected-result {
        background-color: ${Colors.grey90};
    }
`;

export {
  StyledSearchBarContainer, StyledSeeMore,
  StyledSearchContent, StyledSearchContainer,
};
