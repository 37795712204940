import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

const StyledMenuItem = styled(MenuItem)`
  &&.menuItem {
    display: flex;
    align-items: center;
  }
`;

const StyledIcon = styled.span`
  vertical-align: middle;
  margin-right: 8px;
`;

export {
  StyledIcon,
  StyledMenuItem,
};
