import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dialog, Box, DialogTitle, DialogActions, Button,
} from '@mui/material';
import VendorEditForm from '../AddVendor/form';
import Breadcrumb from '../../components/Breadcrumb';
import { convertCamelCaseToUpperCase } from '../../utils/utils';
import { reducer } from '../../store/state';
import { get } from '../../api/data';
import Loader from '../../components/Loader';

const EditVendor = () => {
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const initialState = {
    error: '',
    loading: false,
    success: false,
    data: {},
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, data, success } = state;
  const [editSuccess, setEditSuccess] = useState(false);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      dispatch({ type: 'SET_LOADING', payload: true });
      try {
        const res = await get(`vendor/${vendorId}`);
        dispatch({ type: 'SET_SUCCESS', payload: true });
        dispatch({ type: 'SET_DATA', payload: res });
      } catch (err) {
        dispatch({ type: 'SET_ERROR', payload: 'Error fetching vendor details' });
      }
      dispatch({ type: 'SET_LOADING', payload: false });
    };

    fetchVendorDetails();
  }, [vendorId]);

  return (
    <div className="">
      <Dialog
        open={editSuccess}
        onClose={() => navigate('/')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          margin={5}
        >
          <DialogTitle id="alert-dialog-title">
            {`Vendor ${data && data.name} has been updated successfully.`}
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={() => navigate('/')}>View All Vendors</Button>
            <Button variant="contained" onClick={() => navigate(`/vendor/${data.id}`)} autoFocus>
              View Vendor
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Breadcrumb navigation={[
        {
          displayName: convertCamelCaseToUpperCase('Smartvendor'),
          url: `/`,
        },
        {
          displayName: convertCamelCaseToUpperCase('Edit Vendor'),
          url: `/`,
        },
      ]}
      />
      {loading && <Loader loading={loading} />}
      {!loading && success && <VendorEditForm data={data} onSuccess={setEditSuccess} />}
    </div>
  );
};

export default EditVendor;
