import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { generateRandomKey } from '../../utils/utils';
import { StyledMenuItem, StyledIcon } from './style';

interface OptionInterface {
  label: string;
  icon: JSX.Element;
}

interface DropdownProps {
  name: string;
  label: string;
  options: string[] | number[] | OptionInterface[];
  control: Control<any, any>;
  error: FieldError | undefined;
  defaultValue?: string | '';
}

const Dropdown = ({
  name, label, options, control, error, defaultValue,
}: DropdownProps) => {
  return (
    <Box sx={{ width: 'auto', minHeight: '80px' }}>
      <FormControl fullWidth>
        <InputLabel id={label}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{ required: 'Please select an option' }}
          render={({ field }) => (
            <Select
              {...field}
              labelId={label}
              label={label}
              onChange={(event: SelectChangeEvent) => {
                const { value: opt } = event.target;
                field.onChange(opt);
              }}
              error={!!error}
            >
              {options.map(
                (opt: string | number | OptionInterface) => {
                  const val = typeof opt === 'string' || typeof opt === 'number' ? opt : opt.label;
                  const icon = typeof opt !== 'string' && typeof opt !== 'number' && opt.icon;
                  return (
                    <StyledMenuItem key={generateRandomKey()} value={val} className="menuItem">
                      {icon && <StyledIcon>{icon}</StyledIcon>}
                      <span>{val}</span>
                    </StyledMenuItem>
                  );
                },
              )}
            </Select>
          )}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

Dropdown.defaultProps = {
  defaultValue: '',
};

export default Dropdown;
