import styled from 'styled-components';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Colors } from "../../utils/theme";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return Colors.green01;
  }
  if (props.isDragReject) {
    return Colors.lightred;
  }
  if (props.isFocused) {
    return Colors.lightblue;
  }
  return Colors.white03;
};

const StyledDzContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-color: ${Colors.blue};
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  
  &.error {
    border-color: ${Colors.red};
  }
`;

const StyledDropzoneContent = styled.div`
  text-align: center;
`;

const StyledFileDropzoneText = styled.p`
  color: ${Colors.blue};
`;

const StyledPreviewContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  justify-content: center;

  .thumb {
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid ${Colors.white04};
    margin-bottom: 8px;
    margin-right: 20px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
  }

  .thumb-inner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
  }

  img {
    display: block;
    width: auto;
    height: 100%;
  }

  .remove-icon {
    position: absolute;
    top: -18px;
    right: -18px;
    background-color: #F7F7F7;
    svg {
      padding-right: 0px;
    }
  }
`;

const StyledFileIcon = styled(UploadFileIcon)`
  color: ${Colors.blue};
  font-size: 40px;
`;

export { StyledDzContainer, StyledPreviewContainer };
export { StyledDropzoneContent, StyledFileDropzoneText, StyledFileIcon };
