import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  generateRandomKey,
  getFields, formatTitle, formatValue, formatAddress,
} from '../../utils/utils';
import { get } from '../../api/data';
import { reducer } from '../../store/state';
import { ObjectInterface } from '../../store/vendor/index.type';
import Breadcrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

const Vendor = ({
  id, name, addressLine1, addressLine2, city, country, state, zip,
} : ObjectInterface) => {
  const navigate = useNavigate();
  return (
    <Card style={{ marginTop: 20 }} onClick={() => navigate(`/vendor/${id}`)} sx={{ minWidth: 275 }}>
      <CardActionArea>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Product By:
          </Typography>
          <Typography color="rgb(41, 146, 194)" variant="h5" component="div">
            {name.toUpperCase()}
          </Typography>
          <Typography fontSize={16} color="text.secondary" variant="body2">
            {formatAddress({
              addressLine1, addressLine2, city, country, state, zip,
            })}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const Products = () => {
  const { productId } = useParams();
  const initialState = {
    error: '',
    loading: false,
    success: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [product, setProduct] = useState<ObjectInterface>({});
  const { success, loading, error } = state;

  const getProduct = async (id: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    const res = await get(`product/${id}`);
    if (res) {
      dispatch({ type: 'SET_SUCCESS', payload: true });
      dispatch({ type: 'SET_ERROR', payload: '' });
      setProduct(res);
    } else {
      dispatch({ type: 'SET_ERROR', payload: res?.data });
    }
    dispatch({ type: 'SET_LOADING', payload: false });
    return res;
  };

  useEffect(() => {
    if (productId && !success && !loading && !error) getProduct(productId);
  }, [productId, success, loading, error]);

  return (
    <div className="">
      {loading && <Loader loading={loading} />}
      {success && (
      <>
        <Breadcrumb navigation={[
          {
            displayName: 'Smartvendor',
            url: `/`,
          },
          {
            displayName: product.name,
            url: `/`,
          },
        ]}
        />
        <Box
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <TableContainer style={{ margin: 20, width: 'auto' }} component={Paper}>
                <Table>
                  <TableBody>
                    {Object.keys(getFields(product)).map((key) => (
                      <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell className="label">
                          {formatTitle(key)}
                        </TableCell>
                        <TableCell className="label">
                          {formatValue(key, product[key])}
                        </TableCell>
                      </TableRow>
                    ))}
                    {product.documents && product.documents.length
                      ? (
                        <>
                          <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell rowSpan={product.documents.length + 1} className="label">
                              Documents
                            </TableCell>
                          </TableRow>
                          {product.documents.map(({ url, name }: any) => (
                            <TableRow key={generateRandomKey()} sx={{ '& > *': { borderBottom: 'none' } }}>
                              <TableCell className="label">
                                <Link href={url}>
                                  {name.replaceAll("+", " ")}
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )
                      : <div />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Vendor {...product.vendor} />
            </Grid>
          </Grid>
        </Box>
      </>
      )}
    </div>
  );
};

export default Products;
