import React from "react";
import StyledBreadCrumbs from "./index.styles";
import StyledLink from "../Link";
import { AUTHURL } from "../../utils/constants";
import { convertCamelCaseToUpperCase } from "../../utils/utils";

interface BreadCrumbProps {
  navigation: { displayName: string, url?: string, onClick?: () => void; }[],
}

const Breadcrumb: React.FC<BreadCrumbProps> = ({ navigation }) => {
  return (
    <StyledBreadCrumbs>
      <StyledLink href={AUTHURL}>DataHub</StyledLink>
      {navigation.map((val) => {
        return (
          <React.Fragment key={Math.random()}>
            <span>/</span>
            <div className="crumbs">
              <StyledLink href={val?.url} className="dotted-crumbs">
                ...
              </StyledLink>
              <StyledLink href={val?.url} className="display-crumbs" onClick={val?.onClick}>
                {convertCamelCaseToUpperCase(val.displayName)}
              </StyledLink>
            </div>
          </React.Fragment>
        );
      })}
    </StyledBreadCrumbs>
  );
};

export default Breadcrumb;
