import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import userApiSliceReducer from "./user";
import vendorApiSliceReducer from "./vendor";

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  userActions: userApiSliceReducer,
  vendorActions: vendorApiSliceReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export type RootState = ReturnType< typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
