import styled from "styled-components";
import { BreakPoints } from "../../utils/theme";
import { FlexRowDiv } from "../../utils/ReusableStyles";

const StyledLoader = styled(FlexRowDiv)`
    padding: 200px;
    @media screen and (max-width: ${BreakPoints.MediumDevices}){
      padding: 100px;
  }
`;

export default StyledLoader;
