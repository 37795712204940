import axios from "axios";
import { APIHEADERS, DATAAPIURL } from "../utils/constants";

interface ApiRequestPayload {
    endpoint: string;
    apiPayload: object;
}

const post = async ({ endpoint, apiPayload }: ApiRequestPayload) => {
  const token = localStorage.getItem('token');
  const response = await axios.post(`${DATAAPIURL}/${endpoint}`, apiPayload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((err) => err.response);
  return response.data;
};

const get = async (endpoint: string) => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${DATAAPIURL}/${endpoint}`, {
    headers: {
      ...APIHEADERS,
      Authorization: `Bearer ${token}`,
    },
  }).catch((err) => err.response);
  return response.data;
};

const remove = async (endpoint: string) => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(`${DATAAPIURL}/${endpoint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((err) => err.response);
  return response.data;
};

export {
  post,
  get,
  remove,
};
