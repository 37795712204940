import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {
  FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup,
} from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';

interface RadioInputProps {
    name: string;
    label: string;
    options: string[] | number[];
    control: Control<any, any>;
    error: FieldError | undefined;
}

const RadioInput = ({
  name, label, options, control, error,
}: RadioInputProps) => {
  return (
    <Box sx={{ width: 'auto', minHeight: '80px' }}>
      <FormControl fullWidth>
        <FormLabel id={label}>{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          rules={{ required: 'Please select an option' }}
          render={({ field }) => (
            <RadioGroup row {...field}>
              {options.map(
                (opt) => (
                  <FormControlLabel
                    key={opt}
                    value={opt}
                    control={<Radio />}
                    label={opt}
                  />
                ),
              )}
            </RadioGroup>
          )}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default RadioInput;
