const CIRCLELOADINGIMAGE = "/Images/Circle-Loading.svg";
const TICKSORTIMAGE = "/Images/tick-sort.png";
const CLOSEIMAGE = "/Images/close.svg";
const NORESULTFOUNDIMAGE = "/Images/NoResultsFound.png";
const GREENTICKIMAGE = "/Images/GreenTick.png";
const REDCROSSIMAGE = "/Images/RedCross.png";
const SORTUP = "/Images/sort.png";
const SORTUPACTIVE = "/Images/sortActive.png";
const SORTDOWN = "/Images/sortDown.png";
const SORTDOWNACTIVE = "/Images/sortDownActive.png";
const ACCESSDENIED = "/Images/AccessDenied.png";
const CREDITUNION = "/Images/CreditUnions.png";
const BANK = "/Images/Bank.png";
const SMARTVENDORLOGO = "/Images/logo.png";

export {
  CIRCLELOADINGIMAGE, TICKSORTIMAGE, CLOSEIMAGE, NORESULTFOUNDIMAGE, GREENTICKIMAGE,
  REDCROSSIMAGE, SORTUP, SORTUPACTIVE, SORTDOWN, SORTDOWNACTIVE, ACCESSDENIED,
  CREDITUNION, BANK, SMARTVENDORLOGO,
};
