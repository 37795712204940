import React from 'react';
import {
  Box,
} from '@mui/material';
import { ObjectInterface } from '../../../store/vendor/index.type';
import DataTable from '../../../components/table';

const Contacts = ({ contacts }: ObjectInterface) => {
  return (
    <Box
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
      }}
    >
      <DataTable labelField="isPrimary" data={contacts} />
    </Box>
  );
};

export default Contacts;
