/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import App from './App';
import { store, persistor } from "./store";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </BrowserRouter>
  </StrictMode>,
);
