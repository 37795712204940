import React from "react";
import { CIRCLELOADINGIMAGE } from "../../utils/Images";
import StyledLoader from "./index.styles";

interface LoadingProps {
    loading: boolean,
}

const Loader = ({ loading }: LoadingProps) => (
  <StyledLoader>
    {loading && <img src={CIRCLELOADINGIMAGE} data-testid="loader" alt="loading" />}
  </StyledLoader>
);

export default Loader;
