import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PieItemIdentifier, DefaultizedPieValueType } from '@mui/x-charts';
import { useNavigate } from 'react-router';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Stack, Typography } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import TabView from '../../components/tabs';
import DataTable from '../../components/table';
import { ObjectInterface } from '../../store/vendor/index.type';
import { BUSINESSTYPE, RISKCHARTCOLORS } from '../../utils/constants';

export interface ChartDataInterface {
  value: number;
  label: string;
  color?: string;
}

export interface TabPropsInterface {
  type: string;
  data: ChartDataInterface[];
}
const sizing = {
  margin: { right: 3 },
  width: 800,
  height: 400,
};

const Dashboard = React.memo(({ type, data }: TabPropsInterface) => {
  const navigate = useNavigate();
  const {
    vendors,
    count,
  } = useSelector((state: RootState) => state.vendorActions);
  const [selected, setSelected] = useState<DefaultizedPieValueType>();
  const [filteredData, setFilteredData] = useState<ObjectInterface[]>(vendors);

  const handleSliceClick = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    itemIdentifier: PieItemIdentifier,
    item: DefaultizedPieValueType,
  ) => {
    setSelected(item);
  };

  const handleRowClick = (row: ObjectInterface) => {
    return navigate(`/vendor/${row.id}`);
  };

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / count;
    return `${(percent * 100).toFixed(0)}%`;
  };

  useEffect(() => {
    if (vendors && selected) setFilteredData(vendors.filter((v) => v[type] === selected.label));
  }, [vendors, selected]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <PieChart
        series={[
          {
            outerRadius: 150,
            data,
            arcLabel: getArcLabel,
            cx: 150,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontSize: 14,
          },
        }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 100,
          },
        }}
        onClick={handleSliceClick}
        {...sizing}
      />
      {selected && selected.label && (
      <>
        <Typography alignSelf="flex-start" variant="h5" m={2}>
          <span style={{ display: 'inline', color: selected.color }}>
            {selected.label}
          </span>
          {type === 'risk' && ' Risk Vendors'}
        </Typography>
        <DataTable
          data={filteredData}
          onRowClick={handleRowClick}
        />
      </>
      )}
    </Stack>
  );
});

const Page = () => {
  const {
    loading,
    vendors,
  } = useSelector((state: RootState) => state.vendorActions);

  const chartRiskData = vendors && RISKCHARTCOLORS.map(
    (r) => ({ ...r, id: r.label, value: vendors.filter((ven) => ven.risk === r.label).length }),
  );

  const chartTypeData = vendors && BUSINESSTYPE.map(
    (r) => ({ label: r, id: r, value: vendors.filter((ven) => ven.type === r).length }),
  );

  const tabs = [
    {
      label: 'RISK LEVEL',
      component: <Dashboard
        type="risk"
        data={chartRiskData.filter((d) => !!d.value)}
      />,
    },
    {
      label: 'TYPE',
      component: <Dashboard
        type="type"
        data={chartTypeData.filter((d) => !!d.value)}
      />,
    },
  ];

  return (
    <div className="">
      <Breadcrumb navigation={[
        {
          displayName: 'Smartvendor',
          url: `/`,
        },
        {
          displayName: 'Dashboard',
          url: `/`,
        },
      ]}
      />
      {loading ? <Loader loading={loading} />
        : (
          <TabView tabs={tabs} />
        )}
    </div>
  );
};

export default Page;
