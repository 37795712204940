import React, { useEffect } from 'react';
import { useDropzone, FileWithPath, Accept } from 'react-dropzone';
import { FieldError, Merge } from 'react-hook-form';
import {
  FormHelperText, IconButton, Stack, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import {
  StyledDzContainer,
  StyledDropzoneContent,
  StyledFileDropzoneText,
  StyledPreviewContainer,
  StyledFileIcon,
} from './style';

const getFileType = (file: string) => {
  const fileType = file.split('/').pop()?.toLowerCase();
  if (fileType && ['png', 'jpg', 'jpeg', 'gif'].includes(fileType)) {
    return 'image';
  } if (fileType && fileType === 'pdf') {
    return 'pdf';
  } if (fileType && ['doc', 'docx', 'xls', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(fileType)) {
    return 'officeapps';
  }
  return 'unknown';
};

interface FileInterface extends FileWithPath {
  id?: string;
  preview?: string;
  url?: string;
}

interface ThumbnailInterface {
  file: FileInterface;
  onRemove: (file: FileInterface) => void;
}

const Thumbnail = ({ file, onRemove }: ThumbnailInterface) => {
  const filePath = file.preview || file.url;
  const fileType = file.path && getFileType(file.type);
  let thumb = <div />;
  if (fileType === 'image') {
    thumb = (
      <img
        src={filePath}
        alt={file.name}
        onLoad={() => {
          if (file.preview) URL.revokeObjectURL(file.preview);
        }}
      />
    );
  } else if (fileType === 'pdf') {
    thumb = <embed src={filePath} type="application/pdf" width="100%" height="600px" />;
  } else if (filePath && fileType === 'officeapps') {
    thumb = (
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
        title="thumnail image"
        className="disable-pointer"
        style={{ border: 'none', objectFit: 'contain' }}
      />
    );
  }
  return (
    <Stack flexDirection="column" alignItems="center" flexBasis={0} flexGrow={1} margin={1}>
      <div className="thumb" key={file.path}>
        <div className="thumb-inner">
          {thumb}
        </div>
        <IconButton className="remove-icon" onClick={() => onRemove(file)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Typography>{file.name}</Typography>
    </Stack>
  );
};

interface Props {
  onChange: (files: FileInterface[]) => void;
  onRemove?: (id: string) => void | undefined;
  files: FileInterface[];
  error?: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  fileFormat?: Accept | undefined;
}
const ControlledDropzone = ({
  files, onChange, error, onRemove, fileFormat,
}: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    onDrop: (acceptedFiles) => {
      const filesWithPreviews = acceptedFiles.map((file) => {
        const copiedFile: FileInterface = file;
        copiedFile.id = uuidv4();
        copiedFile.preview = URL.createObjectURL(file);
        return copiedFile;
      });
      onChange([...files, ...filesWithPreviews]);
    },
  });

  const removeFile = (file: FileInterface) => {
    const excludedFiles = files.filter((f: FileInterface) => {
      return (f.id && file.id && f.id !== file.id)
      || (file.path && file.path && f.path !== file.path);
    });
    if (file.id && onRemove) onRemove(file.id);
    onChange(excludedFiles);
  };

  const thumbs = files.map((file: FileInterface) => {
    return <Thumbnail file={file} onRemove={removeFile} />;
  });

  useEffect(() => {
    return () => {
      files.forEach(({ preview }: FileInterface) => {
        if (preview) URL.revokeObjectURL(preview);
      });
    };
  }, []);
  return (
    <div>
      <StyledDzContainer {...getRootProps({ className: `dropzone ${!!error && 'error'}` })}>
        <input {...getInputProps()} />
        <StyledDropzoneContent>
          <StyledFileIcon />
          <StyledFileDropzoneText>
            Drag & drop some files here, or click to select filesWithPreviews
          </StyledFileDropzoneText>
        </StyledDropzoneContent>
      </StyledDzContainer>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
      <StyledPreviewContainer>{thumbs}</StyledPreviewContainer>
    </div>
  );
};

ControlledDropzone.defaultProps = {
  onRemove: undefined,
  error: undefined,
  fileFormat: ['.png', '.jpg', '.jpeg', '.gif', '.pdf', '.xlsx', '.docx', '.pptx'],
};

export default ControlledDropzone;
export type { FileInterface };
