import styled from "styled-components";

const StyledTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: #6F7781;
`;

const StyledProgressBar = styled.div`
    padding: 20px;
    max-width: 780px;
    margin: 0 auto;
    .steps {
        display: grid;
        grid-template-columns: auto auto auto;
    }
    svg {
        font-size: 20px;
        padding-right: 5px;
    }
    .step-name {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid whitesmoke;
        padding: 15px 0px;
        &.completed {
            border-bottom: 2px solid #188a44;
            color: #188a44;
        }
    }
    .title-text {
        position: relative;
    }

`;

export { StyledTitle, StyledProgressBar };
